// import React, { Component } from "react";
// import './home.css'
// export default class PopUp extends Component {
//   handleClick = () => {
//    this.props.toggle();
//   };
// render() {
//   return (
//    <div className="modal">
//      <div className="modal_content">
//      <span className="close" onClick={this.handleClick}>&times;    </span>
//      <p>I'm A Pop Up!!!</p>
//     </div>
//    </div>
//   );
//  }
// }


// import React from 'react'

// const AddTripButton = (props) => {
//   return <button onClick={props.addTrip}>Add a trip</button>
// }

// export default AddTripButton

import React, {  useState }  from 'react';
import { Button } from "react-bootstrap";
import {  Modal } from "react-bootstrap";

const Question = ( props ) => {
    const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
//    <div className="Question">
//     <p>{props.title}</p>

//    </div>
  )
 };
 
 export default Question;

import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCqp1-JfjhEvXnbpYmqFx9m64O2M100FKM",
    authDomain: "connekkt-28d1a.firebaseapp.com",
    projectId: "connekkt-28d1a",
    storageBucket: "connekkt-28d1a.appspot.com",
    messagingSenderId: "488708984810",
    appId: "1:488708984810:web:27e2822801dab86fa66c93",
    measurementId: "G-CLBBGQM7HF"
  };
  

  var Firebase =   firebase.initializeApp(firebaseConfig);
  export{
      Firebase
  }
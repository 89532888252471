import React from "react";
import Image from 'react-bootstrap/Image'
import { Dot } from "./dot";
import Question from "./PopUp";
import {Container,Row,Col} from 'react-bootstrap';
import Logo from './../../Images/YOTAP LOGO FULL.png';
import ShopTag from './../../Images/iconSplash1.png';
import noImage from './../../Images/no_image.png';
import shareIcon from './../../Images/share_icon.png';
import UserImg from './../../Images/download.png';
import Image1 from './../../Images/Instagram.png';
import Image2 from './../../Images/Snapchat.png';
import Image3 from './../../Images/Twitter.png';
import Image4 from './../../Images/Facebook.png';
import Image5 from './../../Images/LinkedIn.png';
import Image6 from './../../Images/WhatsApp.png';
import Image7 from './../../Images/Mail1.png';
import Image8 from './../../Images/YouTube.png';
import Image9 from './../../Images/CashApp.png';



import Image10 from './../../Images/Website.png';
import Image11 from './../../Images/PayPal.png';
import Image12 from './../../Images/Website.png';
import Image13 from './../../Images/Website.png';
import Image14 from './../../Images/Soundcloud 1.png';
import Image15 from './../../Images/Spotify.png';
import Image16 from './../../Images/Text.png';
import Image17 from './../../Images/TikTok.png';
import Image18 from './../../Images/Website.png';
import Image19 from './../../Images/Twitch.png';
import Image20 from './../../Images/Apple Music.png';
import Image21 from './../../Images/OnlyFans.png';
import Image22 from './../../Images/Venmo.png';
import Image23 from './../../Images/Clubhouse.png';
import Image24 from './../../Images/Phone.png';
import Image25 from './../../Images/Pintrest.png';
import Image26 from './../../Images/Discord.png';
import Image27 from './../../Images/Document.png';
import Image28 from './../../Images/Menu.png';
import Image29 from './../../Images/Reddit.png';
import Image30 from './../../Images/WeChat.png';
import Image31 from './../../Images/Zelle.png';
import Image32 from './../../Images/Zenly.png';
import Image33 from './../../Images/Safari.png';
import Image34 from './../../Images/kakao-talk.png';
import Image35 from './../../Images/Alipay.png';
import Image36 from './../../Images/Restaurant.png';
import Image37 from './../../Images/GoogleReview.png';
import Image38 from './../../Images/Yelp.png';
import {Firebase} from './../../Config/Firebase/firebase';
import './home.css'
class App extends React.Component {
  constructor(){
    super();
    this.state={
      userName:"",
      title:"",
      location:"",
      fullName:"",
      profileUrl:"",
      userStatus:"",
      profilePicture:"",
      profilePictuer:"",
      BGPictuer:"",
      showUserData:false,
      isBusiness:false,
      bio:"",
      deps:0,
      socialLinks:[],
      buttonsList:[],
      questions: [
        { id: 'fdsd', title: 'Why is the sky blue?' },
        { id: 'adsf', title: 'Who invented pizza?' },
        { id: 'afdsf', title: 'Is green tea overrated?' },
     ],
        displayQuestions: false,

      sociaImages:[
        {
          Image:Image1,
          imgeName:"Instagram"
        },
        {
          Image:Image2,
          imgeName:"Snapchat"
        },
        {
          Image:Image3,
          imgeName:"Twitter"
        },
        {
          Image:Image4,
          imgeName:"Facebook"
        },
        {
          Image:Image5,
          imgeName:"LinkedIn"
        },
        {
          Image:Image6,
          imgeName:"WhatsApp"
        },
        {
          Image:Image7,
          imgeName:"Email"
        },
        {
          Image:Image8,
          imgeName:"YouTube"
        },
        {
          Image:Image9,
          imgeName:"CashApp"
        },
        {
          Image:Image10,
          imgeName:"Website"
        },
        {
          Image:Image11,
          imgeName:"PayPal"
        },
        {
          Image:Image12,
          imgeName:"Custom URL"
        },
        {
          Image:Image13,
          imgeName:"Skype"
        },
        {
          Image:Image14,
          imgeName:"SoundCloud"
        },
        {
          Image:Image15,
          imgeName:"Spotify"
        },
        {
          Image:Image16,
          imgeName:"Text"
        },
        {
          Image:Image17,
          imgeName:"TikTok"
        },
        {
          Image:Image18,
          imgeName:"Triller"
        },
        {
          Image:Image19,
          imgeName:"Twitch"
        },
        {
          Image:Image20,
          imgeName:"Apple Music"
        }
        ,
        {
          Image:Image21,
          imgeName:"OnlyFans"
        }
        ,
        {
          Image:Image22,
          imgeName:"Venmo"
        }
        ,
        {
          Image:Image23,
          imgeName:"Clubhouse"
        }
        ,
        {
          Image:Image24,
          imgeName:"Phone"
        }
        ,
        {
          Image:Image25,
          imgeName:"Pintrest"
        }
        ,
        {
          Image:Image26,
          imgeName:"Discord"
        },
        {
          Image:Image27,
          imgeName:"Document"
        },
        {
          Image:Image28,
          imgeName:"Menu"
        },
        {
          Image:Image29,
          imgeName:"Reddit"
        },
        {
          Image:Image30,
          imgeName:"WeChat"
        },
        {
          Image:Image31,
          imgeName:"Zelle"
        },
        {
          Image:Image32,
          imgeName:"Zenly"
        },
        {
          Image:Image33,
          imgeName:"Safari"
        },
        {
          Image:Image34,
          imgeName:"kakao-talk"
        },
        {
          Image:Image35,
          imgeName:"Alipay"
        },
        {
          Image:Image36,
          imgeName:"Restaurant"
        },
        {
          Image:Image37,
          imgeName:"GoogleReview"
        },
        {
          Image:Image38,
          imgeName:"Yelp"
        }

      ]
    }
  }

   componentDidMount= async()=>{
     var { socialLinks,sociaImages,buttonsList} = this.state;
    var path = this.props.location.pathname;
    
    var userPath = path.slice(1,path.length)
    console.log(userPath)
      await Firebase.firestore().collection("users").where("userName","==",userPath).get()
      .then((querySnapshot)=>{
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc)=>{
            var currentUser = doc.data();
            console.log(currentUser.uid)
            var tutorialsRef = Firebase.firestore().collection("users").doc(currentUser.uid);
            var pepsTmp = currentUser.deps
            if (isNaN(pepsTmp)){

              pepsTmp = 0
              currentUser.deps = 0
            }
            tutorialsRef.update({
              deps:  currentUser.deps + 1
            });
            if(currentUser.isDirectOff==="false"){
              if (currentUser.mediaUrl){
                window.location.href = currentUser.mediaUrl;


            }else{
             
              console.log("No such document2222!");
              this.setState({
                showUserData:false,
                userStatus:"Direct link Not Found",
              })
            }
            }
            else{
              var userMediaName = currentUser.isBusiness==true?"businessMedia":"mediaList";
               Firebase.firestore().collection('users').doc(currentUser.uid).collection(userMediaName).get()
               .then((res)=>{
                 res.forEach((doc)=>{
                   var mediaLinks = doc.data()
                   socialLinks.push(mediaLinks)
                  //  console.log(socialLinks)
                   for(var i=0;i<socialLinks.length;i++){
                     for(var j=0; j<sociaImages.length;j++){
                       if(socialLinks[i].mediaImage===sociaImages[j].imgeName){
                        socialLinks[i].mediaImage=sociaImages[j].Image
                       }
                     }
                   }
                   this.setState({socialLinks:socialLinks})
                 })
               })

               buttonsList = []
               var buttonsName = currentUser.isBusiness==true?"busButtons":"buttons";
               Firebase.firestore().collection('users').doc(currentUser.uid).collection(buttonsName).get()
               .then((res)=>{
                 res.forEach((doc)=>{
                  var buttonsListTmp = doc.data()
                  if (buttonsListTmp.isEnabled == true){
                    buttonsList.push(buttonsListTmp)

                  }
                   this.setState({buttonsList:buttonsList})
                 })
               })



               if (currentUser.isBusiness==true) {
                this.setState({
                
                  showUserData:true,
                  isBusiness:currentUser.isBusiness,
                  userName:currentUser.userName,
                  fullName:currentUser.businessName,
                  profileUrl:currentUser.profileUrl,
                  profilePicture:currentUser.businessPicture,
                  profilePictuer:currentUser.profilePictuer,
                  BGPictuer:currentUser.BGPictuer,
                  bio:currentUser.businessBio,
                  deps:currentUser.deps
                })
              } else {
                this.setState({
                  title:currentUser.title,
                  location:currentUser.location,
                  showUserData:true,
                  isBusiness:currentUser.isBusiness,
                  userName:currentUser.userName,
                  fullName:currentUser.fullName,
                  profileUrl:currentUser.profileUrl,
                  profilePicture:currentUser.profilePicture,
                  BGPictuer:currentUser.BGPictuer,
                  profilePictuer:currentUser.profilePictuer,
                  bio:currentUser.bio,
                  deps:currentUser.deps
                })
              }

             
            }
        })
        } else {
          console.log("No such document!");
          this.setState({
            showUserData:false,
            userStatus:"User Not Found",
          })
        }
      })
      .catch((error)=>{
        console.log(error)
      })
  }
   
  displayQuestion = () => {
    this.setState({
        displayQuestions: !this.state.displayQuestions
    })
}
  render() {
    console.log(this.state.socialLinks)
    let questions = null 

    
    if ( this.state.displayQuestions ) {
      questions = (
      <div>
        <Question key=""
                title="" />
      </div>
      )
 }

    document.body.style.backgroundColor = "#000000";
    //  document.body.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/tdb1-9fab8.appspot.com/o/background.png?alt=media&token=cf527240-7eb8-4f34-aadc-6c29b2258c14')";

    return (
      <div > 
        {
          this.state.showUserData?
        <div >
        <Container style={{ width: "100%", alignSelf: 'flex-start'}}>
        <Row className="justify-content-center">
        <Col > 
        {/* <div class="d-flex justify-content-center">
  <img src={Logo}  className="_logo"/>
</div> */}
<div class="d-flex justify-content-center">
           {/* <img src={noImage} className="_logo"/> */}
  <img src={Logo}  className="_logo"/>
  {/* <img src={noImage} className="_logo"/> */}
</div>

   <div>
      <div className="d-flex justify-content-center position-relative">
      <div className="dotee">  {Dot(this.state.profilePicture === "" || this.state.profilePicture === undefined?this.state.profilePictuer:this.state.profilePicture)} </div> 
        {/* { this.state.isBusiness ? <div className="dotee">  {Dot(this.state.BGPictuer)} </div>: <div className="dotee">  {Dot(this.state.BGPictuer)} </div> } */}
        <img className="_userImg" src={this.state.BGPictuer}  alt="No image" ></img>
      </div>
      </div>
 <Row className="justify-content-center"  style={{ height:"30px"}}>
               <p className="_userName">{this.state.fullName}</p>
           </Row>
           <Row className="justify-content-center"  style={{height:"23px"}} > 
           <p className="_userDes">{this.state.bio}</p></Row>
           {/* <p className="_userDes">{this.state.bio}{this.state.title}</p> */}
          {/* <Row className="justify-content-center"  style={{height:"20px"}}> 
           <p className="_userLocation">{this.state.location}</p></Row> */}
{/* <div class="d-flex justify-content-between" style={{width:"80%",margin:"auto",marginTop:5, backgroundColor:"#EBEBEB"  , borderRadius: "20px"}}>
{this.state.buttonsList.length>0?
<button type="button" class="_buttonDesign"  style={{ width:"48%",color: "white", background: "#353963" }} onClick={()=>window.location.href=this.state.buttonsList.length>0?this.state.buttonsList[0]["attachedLink"]:"No attached link"}>{this.state.buttonsList.length>0?this.state.buttonsList[0]["title"]:"No Title"}</button>:null}
{this.state.buttonsList.length>1?<button type="button" class="_buttonDesign" style={{ width:"48%",color: "white", background: "#353963" }} onClick={()=>window.location.href=this.state.buttonsList.length>1?this.state.buttonsList[1]["attachedLink"]:"No attached link"}>{this.state.buttonsList.length>1?this.state.buttonsList[1]["title"]:"No Title"}</button>:null}
</div> */}
 <Row className="justify-content-center"  style={{ padding: 0,width:"100%",margin:"auto",marginTop:0, /*backgroundColor:"#ffffff" */  borderRadius: "0px"}}>
           <Col lg="10" xs="18" >
              <Row className="justify-content-center"  style={{padding: 7, alignSelf: 'flex-start'}}>
             {this.state.socialLinks.sort((a, b) => a.mediaIndex > b.mediaIndex ? 1 : -1).map((val,i)=>{

return(
  <Col lg="4" xs="4"   style={{padding: 4, alignSelf: 'flex-start'}} >
  <div  style={{padding: 10, alignSelf: 'flex-start',/*backgroundColor:"#343434"*/}}>
  <Row className="justify-content-center" >
  <img
                className="_social_image"
                style={{marginTop: "10px"}}
                  src={val.mediaImage}
                  onClick={()=>window.location.href=val.mediaUrl} 
                />
                 </Row>
   {/* <Image style={{padding: 11, alignSelf: 'flex-start',backgroundColor:"#343434"}}  src={val.mediaImage} */}
    {/* onClick={()=>window.location.href=val.mediaUrl} thumbnail />\  */}
    <Row className="justify-content-center" >
    <p  style={{ backgroundColor:"",color: "#8E8E93",marginBottom: "10px"}} className="_caption" onClick={()=>window.location.href=val.mediaUrl}>{val.mediaName} </p>
     </Row>
    </div>
   </Col>
)})}
          </Row>
 </Col>
           </Row>
           </Col>
           </Row>
           {/* <Row className="justify-content-center" >
           <p  style={{
            color: "#343434",
            textAlign: "center",
            width: "100%",
            height:"23px",
            marginBottom:"0px",
            fontSize: 13,
          }}>POWERED BY</p>
           </Row> */}
           <Row className="justify-content-center" >
             {/* <div className="_userDiv"> */}
             <button type="button" className="_buttonDesign" style={{ width:"300px",height:"60px",color: "#000000", background: "#D8BF3A",fontFamily:"Ubuntu,sans-serif",border:0,fontSize:18,fontWeight:"bolder", fontStyle:"italic" }}onClick={()=>window.location.href="https://connekkt.nl"}>Shop Connekkt</button>
               {/* <img src={ShopTag} onClick={()=>window.location.href="https://www.yotap.co"} className="_shopTag" style={{height:"50px"}, {width:"180px"}}/> */}
         
             {/* </div> */}
            
           </Row>
{questions}
         </Container>
         <div style={{width:"90%",margin:"auto",marginTop:200,textAlign:"center"}}>
        </div>
        </div>
         :
         <div className="_userNotFoundDiv">
         <p className="_userNotFoundtxt">{this.state.userStatus}</p>
       </div>
         }
      </div>
    );
  }
}
export default App;
